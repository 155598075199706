#footer {
    /* position:absolute; */
    left:0;
    bottom:0;
    right:0;
    /* width: 100%; */
    text-align: center;
  }

  #copyright {
       margin: 0;
       color:rgba(0, 0, 0, 0.55);
  }

  #PPlink {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.55);
  }